import React from 'react'

const HeaderGeneric = props => (
  <header id="header">
    <h1>Zanversorgung</h1>
    <p></p>
  </header>
)

export default HeaderGeneric
